import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=33c20dbe"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=33c20dbe&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IdScrollToTop: require('/opt/app/components/ScrollToTop.vue').default,IdFooterNewsletter: require('/opt/app/components/FooterNewsletter.vue').default})
